<template>
  <div class="mt-6">
    <p class="has-text-weight-bold">
      {{ $t("af:profile.documents.title") }}
    </p>

    <p class="has-text-grey-icon">
      {{ $t("af:profile.documents.subtitle") }}
    </p>

    <div v-for="(doc, i) in documents" :key="i">
      <div class="columns setting-tile mx-0 my-2">
        <b-icon
          icon-pack="far"
          icon="vote-yea"
          type="is-blue"
          size="is-medium"
          class="mr-2"
        ></b-icon>

        <span class="has-text-blue setting-tile-filename">
          {{ trimFileName(doc.displayName) }}
        </span>

        <div class="ml-auto">
          <span class="cursor-pointer" @click="downloadFile(doc)">
            <b-icon
              icon-pack="fa"
              icon="download"
              type="is-blue"
              class="mr-2"
            ></b-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FileSaver from "file-saver";

export default {
  name: "UserDocuments",
  computed: {
    ...mapGetters({
      documents: "drawer/getList",
      getFile: "drawer/getFile",
    }),
  },
  async created() {
    await this.$store.dispatch("drawer/fetchMyDocuments");
  },
  methods: {
    ...mapActions({
      download: "drawer/download",
    }),
    trimFileName(filename) {
      if (filename.length < 25 || window.innerWidth > 768) return filename;
      return filename.slice(0, 25) + "...";
    },
    async downloadFile(document) {
      await this.download(document.id);
      let blob = this.getFile(document.id);
      FileSaver.saveAs(blob, document.fileName);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.setting-tile {
  background: $grey-2;
  border-radius: 8px;
  padding: 1.5rem;
}

.setting-tile-filename {
  padding-top: 0.5rem;
}
</style>
